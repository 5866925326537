import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({ data }) => (
  <Layout>
    <SEO title="Startseite" />

    <section className="hero is-primary is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-three-quarters">
              <h1 className="title">Hi</h1>
              <h2 className="subtitle">ich bin Johannes</h2>
              <p>seit vielen Jahren entwerfe und entwickle ich Webseiten und Webanwendungen.</p>
              <p>Meine Erfahrung konnte ich mit übersichtlichen Visitenkarten-Sites sowie mit umfangreichen Webanwendungen, mit RESTful APIs die von automatisch skalierenden Microservices bereitgestellt werden, sammeln.</p>
              <p>Dabei war ich sowohl Einzelkämpfer, Teamplayer als auch Teamlead. Durch meine Zeit bei einigen Startups konnte ich mich stets in alle Bereiche miteinbringen.</p>
            </div>
            <div className="column">
              <Img
                fluid={ data.portraitImage.childImageSharp.fluid }
                alt="Foto von Johannes Hofmann"
                Tag="figure"
                className="image is-fullwidth is-portrait"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="hero is-danger is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="title">Angebot</h2>
              <ul className="has-bullets">
                <li>Entwicklung von Webseiten</li>
                <li>Ladezeiten-Optimierung</li>
                <li>Entwicklung von Webanwendungen</li>
                <li>Build & Deployment Automatismen</li>
                <li>Cloud-Hosting mit AWS</li>
                <li>Consulting & Workshops</li>
                <li>Wenn's sein muss auch Wordpress</li>
              </ul>
            </div>
            <div className="column">
              <h2 className="title">Technologie-Stack</h2>
              <ul className="has-bullets">
                <li>JavaScript (ES6, Node.js)</li>
                <li>Vue.js, React (Gatsby.js)</li>
                <li>Serverless Framework</li>
                <li>AWS</li>
                <li>Docker</li>
                <li>RESTful API (Design, Implementation, Testing)</li>
                <li>PHP (Laravel, Lumen, Wordpress)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container has-text-centered" style={{ marginTop: '5rem' }}>
          <p>Interesse geweckt? Schreiben Sie mir gerne!</p>
          <a href="mailto:hi@tech-steward.de" className="button is-primary is-rounded">hi@tech-steward.de</a>
        </div>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query {
    portraitImage: file(relativePath: { eq: "portrait-johannes-hofmann.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
